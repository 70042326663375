<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <number-input
      v-model="textData"
      clearable
      class="w-full"
      :locale="locale"
      :disabled="disabled"
      :precision="2"
      :upper-limit="filter.upperLimit"
      @update:modelValue="handleFilterInput"
    />
  </div>
</template>

<script>
import typeOf from 'just-typeof'
import NumberInput from '@/components/inputs/number-input'
export default {
  components: {
    NumberInput
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    locale: {
      type: String,
      default: 'en'
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      textData: this.modelValue || null
    }
  },
  computed: {
    disabled() {
      const disable = this.filter && this.filter.disable
      if (disable && typeOf(disable) === 'function') {
        if (this.textData) {
          this.setToNull()
        }
        return true
      }
      return false
    }
  },
  methods: {
    handleFilterInput(v) {
      let value = this.textData || ''
      this.$emit('update:modelValue', value)
    },
    setToNull() {
      this.$emit('update:modelValue', null)
      this.textData = null
    }
  }
}
</script>
